
import { defineComponent, ref, onMounted, watch } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddAccountModal from "@/views/influencer-platform/influencer-accounts/AddAccountModal.vue";
import FilterDropdown from "@/views/influencer-platform/influencer-accounts/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiAccount } from "@/core/api";
import moment from "moment";
import fileDownload from "js-file-download";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

interface Merchant {
  id: number;
  first_name: string;
  last_name: string;
  source: string;
  channel: string;
  status: string;
  created_at: string;
}

interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  advanced_manage: number;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

interface SortOption {
  field: string;
  direction: string;
}

export default defineComponent({
  name: "accounts-index",
  components: {
    MBDatatable,
    AddAccountModal,
    FilterDropdown,
    PermissionCommon,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();

    const loading = ref(true);
    const tableData = ref<Array<Merchant>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExport = ref<boolean>(true);
    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "stage",
        value: "100",
      },
    ]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const userInfo = ref<User>();
    userInfo.value = store.getters.currentUser;

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const tableHeader = ref([
      // {
      //   name: t("influencerAccount.influencerAccountId"),
      //   key: "id",
      //   sortable: true,
      // },
      {
        name: t("influencerAccount.influencer"),
        key: "first_name",
        sortable: false,
      },
      {
        name: t("influencerAccount.source"),
        key: "source",
        sortable: false,
      },
      {
        name: t("influencerAccount.channel"),
        key: "channel",
        sortable: false,
      },
      {
        name: t("influencerAccount.assignedGroup"),
        key: "assigned_group",
        sortable: false,
      },
      {
        name: t("influencerAccount.registrationDate"),
        key: "registration_date",
        sortable: false,
      },
      {
        name: t("influencerAccount.influencerAccountStatus"),
        key: "status",
        sortable: false,
      },
      {
        name: "", // t("common.actions")
        key: "actions",
        sortable: false,
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getAccountList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    });

    const fileExport = () => {
      disabledExport.value = true;
      ApiAccount.exportAccountData({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExport.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const getAccountList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiAccount.getAccountList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          disabledExport.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getAccountList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getAccountList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getAccountList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getAccountList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          if (item == "first_name" || item == "last_name" || item == "mobile") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(1, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getAccountList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getAccountList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(1, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getAccountList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledExport,
      userInfo,
      getAccountList,
      fileExport,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,

      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
    };
  },
});
